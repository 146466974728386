import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
import MiniLayout from "../../components/minimalLayout"
import styles from "../../components/minimalLayout.module.css"


const Yehwan = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <img className={styles.image100}  src="/yehwan-talk-f21.png"></img>
      </MiniLayout>
    </Layout>
  )
}



export default Yehwan
